/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
import numeral from 'numeral';
import { isBlank } from 'adready-api/helpers/common';
import moment from 'moment';

import {
  DATE_FILTER,
  VISITS,
  CURRENCY,
  PERCENTAGE,
  DECIMAL_PERCENTAGE,
  FREQUENCY,
  DECIMAL,
  DEMO_DOMAIN_PART,
  RANGE_CUSTOM,
  RANGE_CAMPAIGN_TO_DATE,
  DEMO_ACCOUNTS,
  DEMO_ADVERTISERS,
  SEARCH_SOLUTION_ID,
  META_SOLUTION_ID,
  CONVERGED_TV_SOLUTION_ID,
  CAMPAIGN_FILTER,
  ADGROUP_FILTER,
  PIXEL_FILTER,
  ADVANCED_FILTER,
  MEDIATYPE_FILTER,
  CREATIVE_FILTER,
  AUDIENCE_FILTER,
  AD_FILTER,
  KEYWORD_FILTER,
  CONVERSION_WINDOW_FILTER,
  EMAIL_VALIDATION_REGEX,
  VIA_ACCOUNT_ID,
  labelConfig,
} from '@/constant';
import formatNumber, { formatNumberAsFullNumber } from '~/util/numeral';
import { formatDate } from '~/util/apiDateFormat';

const VALUE_FORMAT_MAPPING = {
  impressions: VISITS,
  spend: CURRENCY,
  cpm: CURRENCY,
  visits: VISITS,
  cpa: CURRENCY,
  revenue: CURRENCY,
  roas: PERCENTAGE,
  frequency: FREQUENCY,
  pm: DECIMAL,
  reach: '',
  incrementalreachpercentage: DECIMAL_PERCENTAGE,
  incrementality: PERCENTAGE,
  brandlift: PERCENTAGE,
  vcr: PERCENTAGE,
  videoCompletions: VISITS,
  ctr: DECIMAL_PERCENTAGE,
  clicks: VISITS,
  cpc: CURRENCY,
  cpcv: CURRENCY,
};

export function getValueFormat(valueType) {
  if (!valueType) {
    return 'VISITS';
  }
  return VALUE_FORMAT_MAPPING[valueType.toLowerCase()] || 'VISITS';
}

export function toTitleCase(s = '') {
  return s
    .split(' ')
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');
}

export const convertEpochToNYTimezone = (date) => {
  // const d = date ? new Date(date) : new Date();
  // const dTime = d.getTime() + d.getTimezoneOffset() * 60000;
  // const nyTimeString = new Date(dTime).toLocaleString('en-US', { timeZone: 'America/New_York' });
  // const nyTime = new Date(nyTimeString);
  // return nyTime;
  return date;
};

export const offsetDate = (offsetDays) => {
  const newDate = new Date(new Date().setDate(new Date().getDate() - offsetDays));
  return newDate;
};

export const generateDefaultDates = () => {
  const startDate = offsetDate(31);
  const endDate = offsetDate(2);
  const dateRangeOption = '7'; // default dropdown option is last 30 days
  const compareWith = false;
  return { startDate, endDate, dateRangeOption, compareWith };
};

export const generatePixelPageDefaultDates = () => {
  const startDate = new Date(new Date().setDate(new Date().getDate() - 29));
  const endDate = new Date();
  const dateRangeOption = '7'; // default dropdown option is last 30 days
  return { startDate, endDate, dateRangeOption };
};

export const generateFlipDefaultDates = () => {
  const startDate = offsetDate(31);
  const endDate = offsetDate(2);
  const compareStartDate = offsetDate(32);
  const compareEndDate = offsetDate(3);
  const dateRangeOption = '7'; // default dropdown option is last 30 days
  return { startDate, endDate, compareStartDate, compareEndDate, dateRangeOption };
};

// default values when there's no value in the demo accounts list
// this is from dashboard.store.js
export const generateDemoInstanceDates = () => {
  const startDate = new Date(2022, 2, 9);
  const endDate = new Date(2022, 3, 15);
  const compareStartDate = new Date(2022, 2, 9);
  const compareEndDate = new Date(2022, 3, 14);
  return { startDate, endDate, compareStartDate, compareEndDate };
};

export function formatHour(s) {
  let hour = 0;
  if (s === '0') {
    hour = 12;
  } else {
    hour = `${s > 12 ? s - 12 : s}`;
  }
  return `${hour} ${s >= 12 ? 'PM' : 'AM'}`;
}

export const capitalizeStringSentence = (str = '') => {
  if (str !== '') {
    const arr = str.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  }
  return '';
};

export function formatNumberAsLargeNumber(value, format = '') {
  if (!format) {
    return value;
  }
  const isPercentageFormat = format && format.endsWith('%');
  const valueClone = isPercentageFormat ? value / 100 : value;
  const num = numeral(valueClone);
  return num.format(format);
}

export function formatFileSize(bytes) {
  if (bytes === 0) {
    return '0.00 B';
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const units = ['B', 'kB', 'MB', 'GB', 'TB'];
  return `${(bytes / 1024 ** i).toFixed(2) * 1} ${units[i]}`;
}

export function insertAt(array, index, ...elements) {
  array.splice(index, 0, ...elements);
}

export function isAnyBlank(object, fieldList) {
  for (let i = 0; i < fieldList.length; i++) {
    if (isBlank(object[fieldList[i]])) {
      return true;
    }
  }
  return false;
}

export function getDomainPartsFromHost(hostname) {
  const host = hostname;
  const domainParts = host.split('.');
  const subDomain = domainParts.shift();
  const rootDomain = domainParts.join('.');
  return { host, subDomain, rootDomain };
}

export function isDemoInstance() {
  const { subDomain } = getDomainPartsFromHost(window.location.hostname);
  return subDomain.toLowerCase().includes(DEMO_DOMAIN_PART);
}

export function isFlamingoInstance() {
  const { subDomain } = getDomainPartsFromHost(window.location.hostname);
  return subDomain.toLowerCase().includes('flamingo');
}

export function toFixedNumber(num, digits) {
  if (digits === 0) {
    return Math.round(num);
  }
  const pow = 10 ** digits;
  return Math.round(num * pow) / pow;
}

export function dateDiff(s, e) {
  if (isBlank(s) || isBlank(e)) {
    return 0;
  }
  const startDate = moment(s);
  const endDate = moment(e);
  return endDate.diff(startDate, 'days');
}

export function isAdvertiserSetupCompleted(advertiser) {
  if (!advertiser?.selfServe) {
    return true;
  }

  const accountSetup =
    advertiser &&
    advertiser.selfServe &&
    advertiser.accountSetup &&
    advertiser.accountSetup.accountInfoAvailable &&
    advertiser.accountSetup.paymentInfoAvailable;

  return accountSetup;
}

export function formatMetricValue(label, value, includeLabel = false) {
  if (isBlank(label) && label !== 'REACH') {
    return '';
  }
  if (isBlank(value)) {
    value = 0;
  }
  const displayLabel = label.toUpperCase();
  if (label === 'REACH' && includeLabel === true) {
    return displayLabel;
  }
  const valueType = getValueFormat(label);
  const val =
    label === 'ROAS' || label === 'VCR'
      ? formatNumber(value, valueType)
      : formatNumberAsFullNumber(value, valueType);
  return includeLabel ? `${displayLabel}: ${val}` : val;
}

export function scrollToElement(elementId, alignTo = 'top') {
  const el = document.getElementById(elementId);
  if (!el) {
    return;
  }

  if (alignTo === 'top') {
    el.scrollIntoView(true);
    return;
  }

  el.scrollIntoView();
}

export function deepEqual(obj1, obj2) {
  // Check if both arguments are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2; // Compare primitive values directly
  }

  // Check if both objects are null (since typeof null === 'object')
  if (obj1 === null && obj2 === null) {
    return true;
  }

  // Check if one object is null and the other is not
  if (obj1 === null || obj2 === null) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys and values are equal
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function formatFlipDate(date, dateFormat = 'yyyy-MM-DD') {
  return !isBlank(date) ? moment(new Date(date)).format(dateFormat) : '';
}

export function addDaysToDate(date, count) {
  date.setDate(date.getDate() + count);
  return date;
}

export function jsonArrayToCsv(array) {
  const header = Object.keys(array[0]);

  // handle null or undefined values here
  const replacer = (key, value) => value ?? '';

  const rows = array.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
  );

  // join header and body, and break into separate lines
  const csv = rows.join('\r\n');
  return csv;
}

export function downloadCSVFile(items, fileName = 'export.csv') {
  const downloadFileName = fileName.replace(/ /g, '_').toLowerCase();
  const csv = jsonArrayToCsv(items);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, downloadFileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', downloadFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const getCampaignDateRange = (campaigns = [], decideEndDate = false) => {
  let selectedCampaigns = campaigns.filter((val) => val.checked);
  if (!selectedCampaigns.length) {
    selectedCampaigns = campaigns;
  }
  const startDates = selectedCampaigns.map((filterVal) => filterVal.startDate);
  const minStartDate = startDates.sort()[0];
  let endDate = new Date(new Date().setDate(new Date().getDate() - 1));
  let dateRangeOption = RANGE_CAMPAIGN_TO_DATE;

  if (decideEndDate) {
    const endDates = selectedCampaigns.map((filterVal) => filterVal.endDate);
    let maxEndDate = endDates.sort().reverse()[0];
    const yesterday = formatDate(endDate);
    if (maxEndDate >= yesterday) {
      maxEndDate = yesterday;
    } else {
      dateRangeOption = RANGE_CUSTOM;
    }
    endDate = new Date(maxEndDate);
  }
  const compareEndDate = new Date(new Date(endDate).setDate(endDate.getDate() - 1));
  const notAvailable = minStartDate === undefined || endDate === undefined;
  return {
    startDate:
      minStartDate !== undefined ? convertEpochToNYTimezone(new Date(minStartDate)) : minStartDate,
    endDate: endDate !== undefined ? convertEpochToNYTimezone(endDate) : minStartDate,
    dateRangeOption,
    compareStartDate:
      minStartDate !== undefined ? convertEpochToNYTimezone(new Date(minStartDate)) : minStartDate,
    compareEndDate:
      compareEndDate !== undefined ? convertEpochToNYTimezone(compareEndDate) : compareEndDate,
    notAvailable,
  };
};

export function isFormattedValueZero(formattedVal) {
  const val = formattedVal
    .replace('.', '')
    .replace(/0/g, '')
    .replace('%', '')
    .replace('$', '')
    .replace(/,/g, '');
  return isBlank(val);
}

export function convertToNumber(formattedVal) {
  const val = formattedVal
    .replace('%', '')
    .replace('$', '')
    .replace(/,/g, '');
  return +val;
}

export function filterAccountsAndAdvertisersForDemo(userAccounts) {
  if (!isDemoInstance()) {
    return userAccounts;
  }

  const userAccountsCopy = JSON.parse(JSON.stringify(userAccounts));
  const accountKeys = Object.keys(DEMO_ACCOUNTS).map((key) => parseInt(key, 10));
  const advertiserKeys = Object.keys(DEMO_ADVERTISERS).map((key) => parseInt(key, 10));

  // Keep only accounts that are mentioned in DEMO_ACCOUNTS map
  const filteredUserAccounts = userAccountsCopy.filter((ua) => accountKeys.includes(ua.id));

  filteredUserAccounts.forEach((ua) => {
    // Keep only advertisers that are mentioned in DEMO_ADVERTISERS map
    const filteredAdvertisers = ua?.advertisers.filter((adv) => advertiserKeys.includes(adv.id));
    ua.advertisers = filteredAdvertisers ? JSON.parse(JSON.stringify(filteredAdvertisers)) : [];
  });

  return filteredUserAccounts;
}

export function getFiltersToHide(solution, tool, dashboard, dashboardTab) {
  const listOfFiltersToHide = [];

  if (solution?.id === SEARCH_SOLUTION_ID) {
    listOfFiltersToHide.push(PIXEL_FILTER);
    if (['geo', 'report'].includes(dashboardTab)) {
      listOfFiltersToHide.push(ADVANCED_FILTER);
    } else {
      listOfFiltersToHide.push(
        ...[MEDIATYPE_FILTER, CREATIVE_FILTER, AUDIENCE_FILTER, CONVERSION_WINDOW_FILTER]
      );
      if (dashboardTab === 'ads') {
        listOfFiltersToHide.push(KEYWORD_FILTER);
      } else if (dashboardTab === 'keywords') {
        listOfFiltersToHide.push(AD_FILTER);
      }
    }
  } else if (solution?.id === META_SOLUTION_ID) {
    if (['placement', 'report'].includes(dashboardTab)) {
      listOfFiltersToHide.push(ADVANCED_FILTER);
    } else {
      listOfFiltersToHide.push(
        ...[
          MEDIATYPE_FILTER,
          CREATIVE_FILTER,
          AUDIENCE_FILTER,
          CONVERSION_WINDOW_FILTER,
          KEYWORD_FILTER,
        ]
      );
    }
  } else if (solution?.id === CONVERGED_TV_SOLUTION_ID) {
    listOfFiltersToHide.push(PIXEL_FILTER);
    if (['overview', 'measurement'].includes(dashboardTab)) {
      listOfFiltersToHide.push(...[AD_FILTER, KEYWORD_FILTER]);
    } else if (['conversions'].includes(dashboardTab)) {
      listOfFiltersToHide.push(
        ...[
          CAMPAIGN_FILTER,
          ADGROUP_FILTER,
          MEDIATYPE_FILTER,
          CREATIVE_FILTER,
          AUDIENCE_FILTER,
          AD_FILTER,
          KEYWORD_FILTER,
        ]
      );
    } else {
      listOfFiltersToHide.push(...[CAMPAIGN_FILTER, ADGROUP_FILTER, ADVANCED_FILTER]);
    }
  } else if (tool?.title === 'Custom Reports' || tool?.title === 'Agency Reports') {
    listOfFiltersToHide.push(PIXEL_FILTER);
    listOfFiltersToHide.push(DATE_FILTER);
  } else {
    listOfFiltersToHide.push(...[AD_FILTER, KEYWORD_FILTER, CONVERSION_WINDOW_FILTER]);
  }

  return listOfFiltersToHide;
}

export function isFilterOptionDisabled(filtersToHide, option) {
  return filtersToHide && filtersToHide?.length > 0 && filtersToHide?.includes(option);
}

export function isFilterOptionEnabled(filtersToHide, option) {
  return !isFilterOptionDisabled(filtersToHide, option);
}

export function checkForDisablingConversions(dimension) {
  return (
    dimension === 'country' ||
    dimension === 'state' ||
    dimension === 'dma' ||
    dimension === 'device'
  );
}

export function isEmailValid(email) {
  if (!email) {
    return false;
  }
  return email.match(EMAIL_VALIDATION_REGEX);
}

export function isViaAccount(accountId) {
  if (accountId === VIA_ACCOUNT_ID) return true;
  return false;
}

// this function does deep merge operation on label config constants with account level labels and default labels
export function deepMerge(target, source) {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
        if (!target[key]) {
          target[key] = {};
        }
        deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
}

//  getLabel() is function that returs the component specific and account level labels to shown in app
export function getLabel(componentName, accountId) {
  const componentConfig = labelConfig[componentName];
  if (!componentConfig) {
    throw new Error(`Invalid component name: ${componentName}`);
  }

  const { defaultLabel, accountIdSpecificLabel } = componentConfig;
  const accountConfig = accountIdSpecificLabel[accountId] || {};
  return deepMerge({ ...defaultLabel }, accountConfig);
}

function deepMerge2Arrays(baseOptions, accountOptions) {
  const merged = [...baseOptions];
  accountOptions.forEach((accountOption) => {
    const index = merged.findIndex((baseOption) => baseOption.id === accountOption.id);

    if (index !== -1) {
      merged[index] = { ...merged[index], ...accountOption };
    } else {
      merged.push(accountOption);
    }
  });

  return merged;
}

function deepMerge2Objects(baseOptions, accountOptions) {
  const merged = { ...baseOptions };
  for (const key in accountOptions) {
    if (accountOptions.hasOwnProperty(key)) {
      merged[key] = accountOptions[key];
    }
  }
  return merged;
}

// getCorrectLabels is function that returns  solution specific and account  level labels to be displayed.
export function getCorrectLabels(baseOptions, accountOptions) {
  if (!accountOptions) return baseOptions;

  if (Array.isArray(baseOptions) && Array.isArray(accountOptions)) {
    return deepMerge2Arrays(baseOptions, accountOptions);
  }
  return deepMerge2Objects(baseOptions, accountOptions);
}

export function formatAPDate(date, dateFormat = 'yyyy-MM-DD') {
  return !isBlank(date) ? moment(new Date(date)).format(dateFormat) : '';
}

export function compareArrays(a, b) {
  a = a.sort();
  b = b.sort();
  return a.length === b.length && a.every((element, index) => element === b[index]);
}
